<template>
  <div class="container mt-7 pt-3">
    <base-button
      v-if="showScrollTop"
      type="primary"
      rounded
      iconOnly
      size="lg"
      @click="scrollTop()"
      class="floatingBtn"
      ><span class="fa fa-arrow-up"></span
    ></base-button>
    <div>
      <modal
        :show.sync="request_successful"
        modal-classes="modal-dialog-centered customWidth"
        :showClose="false"
      >
        <!-- <h6 slot="header" class="modal-title" id="modal-title-notification">
          Processing live data
        </h6> -->

        <div class="py-3 text-center">
          <img
            style="width:100px;height:124px"
            src="@/assets/img/progress.gif"
          />
          <h4 class="heading mt-4">Live data has been requested</h4>
          <p>
            We are communicating with the external register to validate the
            current record. You can continue to search and we will notify you
            when we have the new data.
          </p>
        </div>

        <template slot="footer">
          <base-button
            type="login"
            class="ml-auto btn-round"
            @click="closeSuccessfulModal()"
            style="text-transform: capitalize;"
          >
            Close
          </base-button>
        </template>
      </modal>
      <modal
        :show.sync="request_error"
        modal-classes="modal-dialog-centered"
        :showClose="false"
      >
        <h6 slot="header" class="modal-title" id="modal-title-notification">
          Error
        </h6>

        <div class="py-3 text-center">
          <i class="fa fa-exclamation-circle error-icon"></i>
          <h4 class="heading mt-4">Could not request for live data</h4>
          <p>
            {{ error_description }}
          </p>
        </div>

        <template slot="footer">
          <base-button
            type="login"
            class="ml-auto"
            @click="request_error = false"
          >
            Close
          </base-button>
        </template>
      </modal>
      <modal
        :show.sync="already_requested"
        modal-classes="modal-info modal-dialog-centered"
        :showClose="false"
      >
        <h6 slot="header" class="modal-title" id="modal-title-notification">
          Live data already requested
        </h6>

        <div class="py-3 text-center">
          <i class="ni ni-check-bold"></i>
          <h4 class="heading mt-4">Live data has been already requested</h4>
          <p>
            You can see live data status
            <base-button type="warning" @click="$router.push('/live')"
              >here</base-button
            >
            or you can do
            <router-link class="text-warning" to="/search"
              >other searches</router-link
            >, you'll get notified when live data is available.
          </p>
        </div>

        <template slot="footer">
          <base-button
            type="login"
            class="ml-auto"
            @click="already_requested = false"
          >
            Close
          </base-button>
        </template>
      </modal>
      <div class="row mt-2 mb-2 align-items-center">
        <div class="col">
          <router-link to="/">Home</router-link> /
          <router-link to="/search">Search</router-link>
          <!-- <span class="link-search text-primary" @click="goBack()">Search</span> -->
          /
          <span>Detail</span>
        </div>
        <div class="col text-right" v-if="detail.company_name">
          Last updated:
          <span v-b-tooltip.hover.bottom :title="last_updated_full_date">{{
            last_updated
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="text-left col">
          <base-button
            @click="goBack()"
            type="default btn-round"
            style="text-transform: none"
          >
            <span class="fa fa-chevron-left"></span> Go back
          </base-button>
        </div>
        <div class="text-right col d-flex" v-if="detail.company_name">
          <base-button type="success btn-round" @click="saveRecord()">
            Save Record <i class="fa fa-save"></i>
          </base-button>
          <div v-if="detail.userapi_status == 'AVAILABLE'">
            <badge
              style="font-size: 16px; padding: 10px !important"
              v-if="
                live_data_status == 'ERROR' || live_data_status == 'REJECTED'
              "
              type="danger"
              class="mr-2"
              ><span>Error, Could not fetch realtime data.</span></badge
            >
            <base-button
              v-if="
                live_data_status == 'ACCEPTED' ||
                  live_data_status == 'INPROGRESS'
              "
              size="md"
              type="success btn-round"
              style="color: #fff; text-transform: none; box-shadow: none"
            >
              Processing Live Data
            </base-button>
            <base-button
              @click="showLiveData()"
              v-else-if="live_data_status == 'COMPLETED' && !show_live"
              size="md"
              type="success btn-round"
              style="color: #fff; text-transform: none; box-shadow: none"
            >
              Show Latest Data
            </base-button>
            <base-button
              @click="showOriginalData()"
              v-else-if="live_data_status == 'COMPLETED' && show_live"
              size="md"
              type="success btn-round"
              style="color: #fff; text-transform: none; box-shadow: none"
            >
              Show Original Data
            </base-button>
          </div>
          <div
            v-if="detail.userapi_status != 'AVAILABLE' && detail.company_name"
          >
            <base-button
              size="md"
              type="light btn-round"
              style="background: grey; color: #fff; border-color: grey; text-transform: none; box-shadow: none"
            >
              Live data update is not available
            </base-button>
          </div>

          <div v-if="detail.userapi_status == 'AVAILABLE'">
            <div v-if="!live_data_status">
              <base-button
                v-if="!loading_request_live"
                style="text-transform: none"
                type="login"
                class="btn-round"
                @click="requestLiveData()"
                >Request live data
              </base-button>
              <spinner v-else size="lg" type="grow"></spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="mt-4 mb-4 text-center">
      <spinner size="lg" type="grow"></spinner>
    </div>
    <div class="mt-2" v-else>
      <record-detail :caption_data="caption"></record-detail>
    </div>
    <div class="text-right mb-3 mt-3">
      <base-button
        @click="goBack()"
        type="default btn-round"
        style="text-transform: none"
      >
        <span class="fa fa-chevron-left"></span> Go back
      </base-button>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import moment from "moment";
//import Moment from "moment";
import Modal from "@/components/Modal.vue";
import RecordDetail from "@/components/RecordDetail";
import randomstring from "randomstring";
export default {
  components: { Spinner, Modal, RecordDetail },
  directives: {
    BTooltip: VBTooltip
  },
  data: function() {
    return {
      detail: { company_name: null },
      loading: true,
      request_error: false,
      error_description: null,
      already_requested: false,
      loading_request_live: false,
      page_title: "Sherlockit",
      show_dialog: true,
      showScrollTop: false,
      time_format: "YYYY-MM-DD hh:mm:ssTZZ",
    };
  },
  metaInfo() {
    return {
      title: this.page_title
    };
  },
  mounted() {
    this.getDetail();
    window.addEventListener("scroll", this.handleScroll);
    this.$store.dispatch("set_request_successful", false);
  },
  computed: {
    request_successful() {
      return this.$store.getters.request_successful;
    },
    caption() {
      return this.detail.caption || {};
    },
    last_updated() {
      if (!this.detail.company_name) {
        return "";
      } else {
        var d = this.detail.last_updated;
        d = moment(d,this.time_format);
        // let diff = Moment(new Date()).diff(d, "month");
        // if (diff != 0) {
        //   return d.format("ll");
        // } else {
        //   return d.format("ll");
        // }
        //return d.format("ll");
        var last = d.fromNow();
        return last;
      }
    },
    last_updated_full_date() {
      if (!this.detail.company_name) {
        return "";
      } else {
        var d = this.detail.last_updated;
        d = moment(d,this.time_format);
        //return d.format("LLLL");
        var last = d.format("LLLL");
        return last;
      }
    },

    live_data_status() {
      var status = null;
      this.$store.getters.live_data.forEach(data => {
        if (
          data.query == this.$route.params.urn &&
          data.country_code == this.$route.params.country
        ) {
          return (status = data.response_status);
        }
      });
      return status;
    },

    live_data_content() {
      var content = null;
      this.$store.getters.live_data.forEach(data => {
        if (
          data.query == this.$route.params.urn &&
          data.country_code == this.$route.params.country
        ) {
          return (content = data);
        }
      });
      return content;
    },

    live_data_urn() {
      let urn = null;
      if (this.live_data_content) {
        let lr = this.live_data_content.latest_record;
        if (lr[0] && lr[0] != "None") {
          urn = lr[0];
        }
      }
      return urn;
    },

    show_live() {
      if (this.$route.query.live) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    closeSuccessfulModal() {
      this.$store.dispatch("set_request_successful", false);
    },
    scrollTop() {
      document.querySelector("#app").scrollIntoView({
        behavior: "smooth"
      });
    },
    handleScroll() {
      this.showScrollTop = scrollY >= 400;
    },
    goBack() {
      this.$router.back();
      // if (this.$store.getters.search_url) {
      //   this.$router.push(this.$store.getters.search_url);
      // } else {
      //   this.$router.push("/search");
      // }
    },
    getDetail() {
      this.loading = true;
      let urn = this.$route.params.urn;
      if (
        this.show_live &&
        this.live_data_status == "COMPLETED" &&
        this.live_data_urn
      ) {
        urn = this.live_data_urn;
      }
      this.$axios
        .get(`search/detail/${urn}-${this.$route.params.country}/`)
        .then(res => {
          this.detail = res.data;
          this.loading = false;
          this.page_title = res.data.company_name + " | " + "Sherlockit";
        })
        .catch(err => {
          if (err.response) {
            this.loading = false;
            if (err.response.status == 500) {
              this.$router.push("/500");
            } else if (err.response.status == 404) {
              //this.$router.push("/404");
              this.$toasted.show(`This record has been updated. Please search again to see the updated record`, {
                  type: "error",
                  position: "top-right",
                  duration: 5000,
                  action: [
                    {
                      text: "x",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    }
                  ]
                });
              this.$router.back();
            }
          }
        });
    },
    handleLiveRes(res) {
      if (res.response_status == "REJECTED" || res.response_status == "ERROR") {
        this.request_error = true;
        this.error_description = res.error_description;
      } else if (
        res.response_status == "INPROGRESS" ||
        res.response_status == "ACCEPTED"
      ) {
        if (
          this.$store.getters.live_data.filter(d => d.query == res.query)
            .length == 0
        ) {
          this.$store.dispatch("requestLiveData", res);
          this.$store.dispatch("set_request_successful", true);
        } else {
          this.already_requested = true;
        }
      }
    },
    requestLiveData() {
      this.loading_request_live = true;
      const str = randomstring.generate({ length: 80 });
      this.$store.commit("set_live_data_company", this.detail.company_name);
      this.$axios
        .get(
          `search/realtime/?query=${this.$route.params.urn}&request_type=SINGLERECORD&country_code=${this.$route.params.country}&user_reference=${str}&query_type=URN`
        )
        .then(res => {
          this.loading_request_live = false;
          this.handleLiveRes(res.data);
        })
        .catch(err => {
          this.loading_request_live = false;
          if (err.response) {
            this.request_error = true;
            if (err.response.status == 500) {
              this.error_description = "Something went wrong in server";
            }
          }
        });
    },
    showLiveData() {
      this.$store.dispatch("clear_notification");
      this.$router.push(this.$route.path + "?live=true");
    },
    showOriginalData() {
      this.$router.push(this.$route.path);
    },
    saveRecord() {
      const data = this.detail;
      if (!data) {
        return false;
      }

      let f_query = "";
      if (this.$route.query.q) {
        f_query = this.$route.query.q;
      }

      this.$axios
        .post("search/save/", {
          country_code: data.country_code,
          urn: data.urn,
          query: f_query
        })
        .then(res => {
          if (res.status == 200) {
            this.$toasted.show(`Record saved successfully`, {
              type: "success",
              position: "top-right",
              duration: 5000,
              action: [
                {
                  text: "x",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
              ]
            });
          }
        })
        .catch(err => {
          if (err.response) {
            this.$toasted.show(`Could not save record`, {
              type: "error",
              position: "top-right",
              duration: 5000,
              action: [
                {
                  text: "x",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
              ]
            });
          }
        });
    }
  },
  watch: {
    $route() {
      this.getDetail();
    }
  }
};
</script>
<style>
.item {
  margin-top: 10px;
}
.underline {
  width: 100%;
  border: 1px solid #ebe5e5;
  display: inline-block;
}
.scroll {
  overflow-x: scroll;
}
.link-search:hover {
  cursor: pointer;
}
</style>
