export default {
  mounted() {},
  methods: {
    isArray(a) {
      return !!a && a.constructor === Array;
    },

    isLiteralObject(a) {
      return !!a && a.constructor === Object;
    },

    //**************************************************************************************************
    format_json(json, ele, single=false) {
      for (var attr in json) {
        if (this.isArray(json[attr])) {
          if (json[attr].length > 0 && !this.isLiteralObject(json[attr][0])) {
            ele.appendChild(this.get_array_format(attr, json[attr]));
          } else {
            ele.appendChild(this.get_table_format(attr, json[attr]));
          }
        } else if (this.isLiteralObject(json[attr])) {
          ele.appendChild(this.get_object_format(attr, json[attr]), single);
        } else {
          ele.appendChild(this.get_div_format(attr, json[attr], single));
        }
      }
    },
    //**************************************************************************************************
    get_table_format(key, arr) {
      var outerDiv = document.createElement("div");
      outerDiv.classList.add("section-container");
      var innerKeyDiv = document.createElement("div");
      var innerValDiv = document.createElement("div");
      innerValDiv.classList.add("table-container");
      innerKeyDiv.textContent = key;
      innerKeyDiv.classList.add("fj-row");
      if (arr.length) {
        var collapseLink = document.createElement("a");
        collapseLink.innerHTML = "<i class='fa fa-chevron-circle-down'></i>";
        collapseLink.classList.add("fj-btn");
        // collapseLink.setAttribute("href", "#");
        innerKeyDiv.appendChild(collapseLink);
      }
      // populate table with header data
      var table = document.createElement("table");
      var thead = document.createElement("thead");
      var tr = document.createElement("tr");
      for (var attr in arr[0]) {
        var th = document.createElement("th");
        th.textContent = attr;
        tr.appendChild(th);
      }
      thead.appendChild(tr);
      table.appendChild(thead);
      // fill table body
      var tbody = document.createElement("tbody");
      for (var i = 0; i < arr.length; i++) {
        var rec = arr[i];
        var trInner = document.createElement("tr");
        for (var col in rec) {
          var td = document.createElement("td");
          var obj = { col: rec[col] };
          this.format_json(obj, td, true);
          trInner.appendChild(td);
        }
        tbody.appendChild(trInner);
      }
      table.appendChild(tbody);
      innerValDiv.appendChild(table);
      innerValDiv.classList.add("hide");
      innerValDiv.classList.add("no-height");
      outerDiv.appendChild(innerKeyDiv);
      outerDiv.appendChild(innerValDiv);
      return outerDiv;
    },

    //**************************************************************************************************
    get_object_format(key, obj, single=false) {
      var outerDiv = document.createElement("div");
      outerDiv.classList.add("section-container");

      if (!single) {
        var innerKeyDiv = document.createElement("div");
        innerKeyDiv.textContent = key;
        innerKeyDiv.classList.add("fj-row");
        outerDiv.appendChild(innerKeyDiv);
      }

      var innerValDiv = document.createElement("div");
      this.format_json(obj, innerValDiv);
      outerDiv.appendChild(innerValDiv);
      return outerDiv;
    },

    //**************************************************************************************************
    get_div_format(key, val, single) {
      var outerDiv = document.createElement("div");

      if (!single) {
        var innerKeyDiv = document.createElement("div");
        innerKeyDiv.textContent = key;
        if (key === "*") {
          innerKeyDiv.classList.add("bullet-char");
          outerDiv.classList.add("flex-bullet-char");
        }
        innerKeyDiv.classList.add("key");
        outerDiv.appendChild(innerKeyDiv);
      }

      var innerValDiv = document.createElement("div");
      innerValDiv.textContent = val;
      if (!single) {
        innerValDiv.classList.add("val");
      }
      outerDiv.appendChild(innerValDiv);
      outerDiv.classList.add("d-flex");

      return outerDiv;
    },

    //**************************************************************************************************
    get_array_format(key, arr) {
      var outerDiv = document.createElement("div");
	    outerDiv.classList.add("section-container");
      var innerKeyDiv = document.createElement("div");
      innerKeyDiv.textContent = key;
      // innerKeyDiv.classList.add("row");
      innerKeyDiv.classList.add("sub-info");
      outerDiv.appendChild(innerKeyDiv);

      var innerValDiv = document.createElement("div");

      for (var i = 0; i < arr.length; i++) {
        innerValDiv.appendChild(this.get_div_format("*", arr[i]));
      }

      outerDiv.appendChild(innerValDiv);

      return outerDiv;
    },

    //**************************************************************************************************
    make_deep_nested_collapsible(ele, parent) {
      var depth = 0;

      while (ele != parent) {
        ele = ele.parentElement;
        depth++;

        if (depth % 4 == 0) {
          if (ele.previousSibling && ele.previousSibling.textContent != "") {
            ele.classList.add("hide");

            var collapseLink = ele.previousSibling.querySelector("a");
            if (!collapseLink) {
              ele.previousSibling.innerHTML =
                ele.previousSibling.textContent +
                '<a class="btn" href="#">&#43;</a>';
            }
          }
        }
      }
    },

    formatData(id, caption_data, country_name = "", title_selector = "") {
      var caption = caption_data;
      var country = country_name;
      if (title_selector) {
        var title = document.querySelector(`${title_selector}`);
        title.textContent = "Caption Formatting - " + country;
      }
      var div = document.querySelector(`#${id}`);
      this.format_json(caption, div);
      var nested_divs = div.querySelectorAll(".hide");
      for (var ele of nested_divs) {
        this.make_deep_nested_collapsible(ele, div);
      }
      // implement show/hide behavior for table divs
      var links = div.getElementsByTagName("a");
      for (var link of links) {
        link.addEventListener("click", function() {
          var parent = this.parentElement;
          var next = parent.nextSibling;
          next.classList.toggle("hide");
          if (next.classList.contains("no-height")) {
            next.classList.toggle("no-height");
          } else {
            setTimeout(() => {
              next.classList.toggle("no-height");
            }, 400);
          }
          if (this.innerHTML.includes("up")) {
            this.innerHTML = "<i class='fa fa-chevron-circle-down'></i>";
          } else {
            this.innerHTML = "<i class='fa fa-chevron-circle-up'></i>";
          }
        });
      }
    }
  }
};
