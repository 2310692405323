<template>
  <div>
    <div id="caption"></div>
  </div>
</template>
<script>
import format_data from "@/mixins/format_json";
export default {
  mixins: [format_data],
  props: ["caption_data"],
  mounted() {
    this.updateData();
  },

  methods: {
    updateData() {
      document.getElementById("caption").innerHTML = "";
      this.formatData("caption", this.caption_data);
    }
  },

  watch: {
    caption_data() {
      this.updateData();
    }
  }
};
</script>

<style>
table,
td,
th {
  border: 1px solid lightgray;
}

thead {
  background-color: rgb(229, 235, 228);
}

th,
td {
  padding: 5px;
}

.table-container>table {
  width: 100%;
}

.table-container th {
  color: black;
}

.fj-row {
    width: 100%;
    font-weight: bold;
    padding: 10px 10px 5px 10px;
    margin-bottom: 0px;
    margin-top: 10px;
    background: rgb(244, 245, 247);
    color: rgb(42, 42, 42);
}

.key {
  display: inline-block;
  color: black;
  padding: 5px 15px 5px 10px;
  width: 50%;
}

.val {
  display: inline-block;
  padding: 5px 5px 5px 0;
  color: black;
  width:50%;
}

.fj-btn {
  margin-left: 5px;
  font-weight: normal;
  color: black;
  cursor: pointer;
  /* border: 1px solid rgb(248, 213, 213); */
  width: 1.5em;
  display: inline-block;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  /* border-radius: 50%; */
}
.fj-btn:hover {
  color: lightgrey !important;
}

.hide {
  /* opacity: 0;
  overflow: hidden; */
}

a {
  text-decoration: none;
}

.table-container {
  overflow-x: auto;
  transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
}
.no-height {
  height: 0;
}
</style>
